<template>
  <div class="main-container">
    <div class="main-content">
      <div class="section-schedule">
        <div class="schedule-header">
          <h1>
            <span><i class="fal fa-alarm-clock"></i></span
            >{{ $t("schedule.title") }}
          </h1>
        </div>
        <div class="schedule-content">
          <div class="form-content">
            <v-form>
              <v-row class="main-row-content">
                <v-col cols="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    :label="$t('schedule.name')"
                    v-model="schedule.name"
                    :hint="`${server_errors.name}`"
                    persistent-hint
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="6" md="6">
                  <v-select
                    style="text-transform: capitalize"
                    outlined
                    dense
                    :label="$t('schedule.type')"
                    v-model="schedule.purpose"
                    :item-value="'name'"
                    :item-text="'name'"
                    :items="purposeItem"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-form>
          </div>

          <div class="check-schedule">
            <v-row style="height: auto">
              <v-col cols="4" md="4">
                <v-alert
                  type="info"
                  elevation="5"
                  outlined
                  light
                  text
                  icon="mdi-alert-circle-outline"
                  style="margin: 0; padding: 8px"
                >
                  <h5 class="alert-title">{{ $t("schedule.empty") }}</h5>
                </v-alert>
              </v-col>
              <div style="width: 100%; display: flex">
                <v-col cols="6">
                  <label class="text-date-label label-input"
                    >{{ $t("schedule.startDate")
                    }}<span class="text-show-error">{{
                      server_errors.from
                    }}</span></label
                  >
                  <DatePicker
                    class="date-time"
                    style="width: 100%"
                    type="date"
                    valueType="format"
                    slot="activator"
                    :append-to-body="true"
                    v-model="schedule.from"
                  ></DatePicker>
                </v-col>

                <v-col cols="6">
                  <label class="text-date-label label-input"
                    >{{ $t("schedule.endDate")
                    }}<span class="text-show-error">{{
                      server_errors.to
                    }}</span></label
                  >
                  <DatePicker
                    class="date-time"
                    style="width: 100%"
                    type="date"
                    valueType="format"
                    slot="activator"
                    :append-to-body="true"
                    v-model="schedule.to"
                  ></DatePicker>
                </v-col>
              </div>
            </v-row>
          </div>
          <div class="block-btn">
            <v-btn
              class="btn-save-change"
              :loading="btnLoading"
              @click="Updated"
            >
              {{ $t("schedule.save") }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  props: {
    schedule: {},
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      btnLoading: false,
      purposeItem: [{ name: "normal" }, { name: "roster" }],
      server_errors: {
        name: "",
        from: "",
        to: "",
      },
    };
  },
  methods: {
    Updated() {
      this.btnLoading = true;
      this.$axios
        .put(`company/check-in-schedule/${this.schedule.id}`, this.schedule)
        .then((res) => {
          if (res.data.code === 200) {
            setTimeout(() => {
              this.$emit("success");
              this.$emit("close");
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.btnLoading = false;
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            const obj = error.response.data.errors;
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
            }
          }
          this.btnLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.section-schedule {
  width: 100%;
  height: auto;
  //background-color: red;
  display: flex;
  flex-direction: column;
  position: relative;

  .schedule-header {
    width: 100%;
    height: 80px;
    // background-color: yellow;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      font-family: $font-family;
      font-size: 28px;
      margin-top: 10px;
      font-weight: normal;
      color: #18bbec;
    }

    span {
      color: #18bbec;
      margin-right: 10px;
    }
  }

  .schedule-content {
    width: 100%;
    height: 100vh;
    // background-color: #18BBEC;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;

    .form-content {
      width: 800px;
      height: 100px;
      background-color: white;
      box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
      padding: 20px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;

      .main-row-content {
        width: 760px;
        height: auto;
        margin-top: 10px;
      }
    }

    .check-schedule {
      width: 800px;
      height: 200px;
      background-color: white;
      box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
      position: relative;
      border-radius: 8px;
      display: flex;
      padding: 35px;
      margin-top: 30px;

      .alert-title {
        font-size: 14px;
        color: #363636;
        text-transform: capitalize;
        font-weight: normal;
        font-family: $font-family;
        padding: 0;
        border: 0;
        margin-top: 5px;
      }
    }
  }
}

.block-btn {
  position: absolute;
  top: 350px;
  margin-left: 680px;
}
</style>
