<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("schedule.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field
          class="btn btn-color btn-border btn-border-color"
          outlined
          dense
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('Search.search')"
          v-model="searchItem"
          @keypress.enter="searchFilterItem"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">
          {{ $t("schedule.create") }}
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view">
          <thead>
            <tr>
              <th class="text-center">
                {{ $t("schedule.no") }}
              </th>
              <th class="text-left">
                {{ $t("schedule.name") }}
              </th>
              <th class="text-left">
                {{ $t("schedule.type") }}
              </th>
              <th class="text-left">
                {{ $t("schedule.startDate") }}
              </th>
              <th class="text-left">
                {{ $t("schedule.endDate") }}
              </th>
              <th class="text-center" colspan="2">
                {{ $t("schedule.addEmpAndTime") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in listSchedules" :key="idx">
              <td>
                {{ idx + 1 }}
              </td>
              <td>
                {{ item.name }}
              </td>
              <td>
                <div class="highlight--schedule">
                  <span
                    class="schedule--byEmployee"
                    >{{ item.purpose }}</span
                  >
                </div>
              </td>
              <td>
                <div v-if="item.from">
                  {{ item.from }}
                </div>
                <div v-else>
                  <span class="show_notNull">-</span>
                </div>
              </td>

              <td>
                <div v-if="item.to">
                  {{ item.to }}
                </div>
                <div v-else>
                  <span class="show_notNull">-</span>
                </div>
              </td>
              <td class="text-center">
                <v-menu
                  transition="slide-y-transition"
                  bottom
                  v-if="item.purpose == 'normal'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="attractEmployee(item)"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                    >
                      <i
                        class="fal fa-users"
                        style="font-size: 22px; color: #719aff"
                      ></i>
                    </v-btn>
                  </template>
                </v-menu>
              </td>
              <td class="text-center">
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="scheduleDetail(item.id)"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                    >
                      <i
                        class="fal fa-stopwatch"
                        style="font-size: 22px; color: #719aff"
                      ></i>
                    </v-btn>
                  </template>
                </v-menu>
              </td>
              <td>
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined v-bind="attrs" v-on="on">
                      <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title
                        class="btn-edit"
                        @click="onEdit(item.id)"
                        >{{ $t("action.edit") }}</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title
                        class="btn-delete"
                        @click="onDeleted(item.id)"
                        >{{ $t("action.delete") }}</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </table>
        <ModalEdit fullscreen title="Edit Schedule">
          <template v-slot="{ close }">
            <Edit
              :schedule="schedule"
              @close="close"
              @success="fetchSchedules()"
            />
          </template>
        </ModalEdit>

        <ModalDelete fullscreen title="Edit Schedule">
          <template v-slot="{ close }">
            <Delete
              :scheduleId="scheduleId"
              @close="close"
              @success="fetchSchedules()"
            />
          </template>
        </ModalDelete>

        <Pagination
          class="mt-10"
          v-if="pagination.total_pages > 1"
          :pagination="pagination"
          :offset="offset"
          @paginate="fetchSchedules"
        >
        </Pagination>
        <Loading v-if="isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../../components/Loading";
import Pagination from "../../../components/Paginate/Pagination";
import Edit from "../../../components/CheckInSchedule/Edit";
import Delete from "../../../components/CheckInSchedule/Delete";

export default {
  components: {
    Loading,
    Pagination,
    Edit,
    Delete,
  },

  data() {
    return {
      searchItem: "",
      offset: 10,
      pagination: {},
      per_page: 10,
      isLoading: true,
      listSchedules: [],
      schedule: [],
      scheduleId: "",
    };
  },
  methods: {
    searchFilterItem() {
      this.fetchSchedules();
    },
    onCreate() {
      this.$router.push({ name: "schedule.create" }).catch(() => {});
    },

    filterSchedule(scheduleId) {
      return (
        this.listSchedules.filter((item) => {
          return item.id == scheduleId;
        })[0] || {}
      );
    },

    onEdit(scheduleId) {
      this.schedule = {
        ...this.filterSchedule(scheduleId),
      };
      this.$store.commit("modalEdit_State", true);
    },

    onDeleted(scheduleId) {
      this.scheduleId = scheduleId;
      this.$store.commit("modalDelete_State", true);
    },

    fetchSchedules() {
      this.isLoading = true;
      this.$axios
        .get(`company/check-in-schedule`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            filter: this.searchItem,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            this.isLoading = false;
            this.listSchedules = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.listSchedules.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
        });
    },

    scheduleDetail(id) {
      this.$router
        .push({
          name: "scheduleDetail.index",
          params: {
            schedule_id: id,
          },
        })
        .catch(() => {});
    },

    attractEmployee(item) {
      if (item.type == "byEmployee") {
        this.$router
          .push({
            name: "scheduleAttractEmployee.index",
            params: {
              schedule_id: item.id,
            },
          })
          .catch(() => {});
      } else {
        this.$router
          .push({
            name: "scheduleAttractPosition.index",
            params: {
              schedule_id: item.id,
            },
          })
          .catch(() => {});
      }
    },
  },
  created() {
    this.fetchSchedules();
  },
};
</script>

<style scoped lang="scss">
.highlight--schedule {
  .schedule--byEmployee {
    background-color: rgba(0, 168, 255, 0.15);
    color: #719aff;
    padding: 3px 12px;
    font-weight: normal;
    border-radius: 40px;
    font-size: 12px;
    font-family: $font-family;
    letter-spacing: 1px;
    user-select: none;
  }

  .schedule--byPosition {
    background-color: rgba(239, 87, 119, 0.2);
    color: #f82c80;
    padding: 3px 12px;
    font-weight: normal;
    border-radius: 40px;
    font-size: 12px;
    font-family: $font-family;
    letter-spacing: 1px;
    user-select: none;
  }
}

.show_notNull {
  font-size: 30px;
  margin-left: 25px;
  color: #d70f64;
}
</style>
